import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { IAddBrackedFormData } from '../../agent/constants/types';

import { VALIDATION_MESSAGES } from './constants';
import { AuthorityTypes } from './types';

const {
  NAME_REQUIRED,
  AUTHORITY_TYPE_REQUIRED,
  MC_NUMBER_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_ID_REQUIRED,
  ZIP_REQUIRED,
  FILE_REQUIRED,
  BROKER_FILE_REQUIRED,
  MIN_OPTION,
  MIN_NUMBER,
  VALID_NUMBER,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  DOT_REQUIRED,
  PACKAGE_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  EMAIL_REQUIRED,
  INVALID_EMAIL,
  TEMPLATE_REQUIRED,
  RATE_CONFIRMATION_PRIVACY_POLICY_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  authority_type: yup.string().required(AUTHORITY_TYPE_REQUIRED),
  dot: yup.string().required(DOT_REQUIRED),
  bankName: yup.string(),
  accounting_number: yup.string(),
  ach_routing_number: yup.string(),
  mc: yup.string().typeError(VALID_NUMBER).required(MC_NUMBER_REQUIRED).min(0, MIN_NUMBER),
  phone: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  stateId: yup.string().required(STATE_ID_REQUIRED),
  zip: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  email: yup.string().email(INVALID_EMAIL).required(EMAIL_REQUIRED).matches(emailPattern, INVALID_EMAIL),
  files: yup
    .array()
    .required(FILE_REQUIRED)
    .test('length', FILE_REQUIRED, function checkLength(arr = []) {
      return !!arr?.length;
    }),
  broker: yup.array().test('length', BROKER_FILE_REQUIRED, function checkLength(arr = []) {
    if (Number(this.parent.authority_type) === AuthorityTypes.Broker) {
      return !!arr?.length;
    } else return true;
  }),
  physical: yup.boolean(),
  packet: yup
    .array()
    .required(PACKAGE_REQUIRED)
    .test('length', PACKAGE_REQUIRED, function checkLength(arr = []) {
      return !!arr?.length;
    }),
  physicalAddress: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    otherwise: schema => schema.notRequired(),
  }),
  physicalCity: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(CITY_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  physicalStateId: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.min(1, MIN_OPTION).required(STATE_ID_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),
  physicalZip: yup.string().when('physical', {
    is: (value: string) => !!value,
    then: schema => schema.required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
    otherwise: schema => schema.notRequired(),
  }),
  templateId: yup.string().test('isTemplateRequired', TEMPLATE_REQUIRED, function checkIsTemplateReq(value) {
    if (Number(this.parent.authority_type) === AuthorityTypes.Carrier) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  privacyPolicy: yup.string().when('authority_type', {
    is: (value: string) => Number(value) === AuthorityTypes.Broker,
    then: schema => schema.min(1, RATE_CONFIRMATION_PRIVACY_POLICY_REQUIRED),
    otherwise: schema => schema.notRequired(),
  }),

  factoring: yup.string().required('Factoring is a required field'),
  factoringCompanyId: yup
    .array()
    .nullable()
    .test('isRequired', 'Factoring Company is Required', function checkisRequired(value) {
      if (Number(this.parent.factoring) === 1) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
  brackets: yup.array().test('length', 'You have to add at least one bracket', function checkLength(arr = []) {
    if (Number(this.parent.factoring) === 1) {
      return !!arr.length;
    }
    return true;
  }),
  aging030: yup
    .string()
    .nullable()
    .test('length', '', function checkLength(el) {
      if (Number(this.parent.factoring) !== 1) {
        return true;
      } else if (Number(this.parent.factoring) === 1) {
        if (Number(el) > 100) {
          return this.createError({
            message: 'Aging must be less or equal than 100',
            path: 'aging030',
          });
        }
        if (Number(el) < 0) {
          return this.createError({
            message: 'Aging must be more or equal than 0',
            path: 'aging030',
          });
        }
        return true;
      }
      return true;
    }),
  aging3160: yup
    .string()
    .nullable()
    .test('length', '', function checkLength(el) {
      if (Number(this.parent.factoring) !== 1) {
        return true;
      } else if (Number(this.parent.factoring) === 1) {
        if (Number(el) > 100) {
          return this.createError({
            message: 'Aging must be less or equal than 100',
            path: 'aging3160',
          });
        }
        if (Number(el) < 0) {
          return this.createError({
            message: 'Aging must be more or equal than 0',
            path: 'aging3160',
          });
        }
        return true;
      }
      return true;
    }),
  aging6190: yup
    .string()
    .nullable()
    .test('length', '', function checkLength(el) {
      if (Number(this.parent.factoring) !== 1) {
        return true;
      } else if (Number(this.parent.factoring) === 1) {
        if (Number(el) > 100) {
          return this.createError({
            message: 'Aging must be less or equal than 100',
            path: 'aging6190',
          });
        }
        if (Number(el) < 0) {
          return this.createError({
            message: 'Aging must be more or equal than 0',
            path: 'aging6190',
          });
        }
        return true;
      }
      return true;
    }),
  lpf: yup
    .string()
    .nullable()
    .test('length', '', function checkLength(el) {
      if (Number(this.parent.factoring) !== 1) {
        return true;
      } else if (Number(this.parent.factoring) === 1) {
        if (el) {
          if (Number(el) < 0) {
            return this.createError({
              message: 'LPF Amount must be 0 or a positive number.',
              path: 'lpf',
            });
          }
          return true;
        } else {
          return this.createError({
            message: 'LPF Amount is a required field',
            path: 'lpf',
          });
        }
      }
    }),
});

export const bracketValidation = (values: IAddBrackedFormData[]) => {
  const percents = values?.map(item => item?.percent) || [];
  return yup.object().shape({
    percent: yup
      .number()
      .required('Percent is a required field')
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .max(100, 'Percent must be less than 100')
      .notOneOf([undefined, 0, null, NaN], 'Value must be more than 0 or equal 1')
      .positive('Value must be positive')
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .moreThan(Math.max(...(percents as number[])), 'Value should be more than existing percents'),
    from: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .lessThan(yup.ref('to'), ''),
    to: yup
      .number()
      .required('To is a required field')
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .positive('Please enter positive number')
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .moreThan(yup.ref('from'), 'To should be more than from'),
  });
};
